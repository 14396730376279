<template>
  <TModal
    :title="!is_edit ? 'Create' : 'Edit'"
    :show="show"
    @update:show="$emit('update:show', $event)"
    @click-create="create()"
    :key="key"
  >
    <template #actions>
      <TButtonCreate
        :options="{ disabled: loading }"
        variant="outline"
        @click="create"
        v-if="!is_edit"
      />
      <TButtonSave
        variant="outline"
        @click="update"
        v-if="is_edit"
        :options="{ disabled: loading }"
      />
    </template>
    <TInputText
      label="Name"
      class="mb-3"
      :value="input.name"
      @update:value="input.name = $event"
    />
    <TInputNumber
      :value="input.max_volume"
      label="Max volume"
      class="mb-3"
      :maskOptions="{ integerLimit: 3 }"
      @update:value="input.max_volume = $event"
    />
    <TInputNumber
      :value="input.max_weight"
      label="Max weight"
      @update:value="input.max_weight = $event"
    />
  </TModal>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    detail: {
      type: Object,
      require: false,
    },
  },
  inputDefault: {
    name: "",
    max_volume: 0,
    max_weight: 0,
  },
  data() {
    return {
      key: "modal",
      loading: false,
      data: this.getInput(),
    };
  },
  watch: {
    detail() {
      this.data = this.getInput();
    },
  },
  computed: {
    is_edit() {
      if (!this.detail) {
        return false;
      }
      return !!this.detail.id;
    },
    input: {
      get: function () {
        return this.data;
      },
      set: function (newValue) {
        return (this.data = newValue);
      },
    },
  },
  methods: {
    create() {
      this.loading = true;
      this.$store
        .dispatch("warehouse.container_types.create", {
          ...this.input,
          max_volume: this.input.max_volume * 1000000,
        })
        .then(() => {
          this.input = this.inputDefault();
          this.$emit("update:show", false);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    update() {
      this.$store
        .dispatch("warehouse.container_types.update", {
          id: this.detail.id,
          attributes: {
            ...this.input,
            max_volume: this.input.max_volume * 1000000,
          },
        })
        .then(() => {
          this.$store.dispatch("warehouse.container_types.fetch");
          this.key = this.lodash.resetKey("modal");
          this.$emit("update:show", false);
        });
    },
    inputDefault() {
      return { ...this.$options.inputDefault };
    },
    getInput() {
      if (!this.is_edit) {
        return this.inputDefault();
      }
      return {
        ...this.detail,
        max_volume: this.detail.max_volume / 1000000,
      };
    },
  },
};
</script>
