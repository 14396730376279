<template>
  <div>
    <TTableAdvance
      :items="list"
      :fields="fields"
      store="warehouse.container_types"
      @click-clear-filter="clearFilter"
      resource=""
      creatable
      deletable
      @click-create="showModalCreate = true"
    >
      <template #append-actions="{ item }">
        <CCol class="px-1 text-center" col="12">
          <TButtonEdit @click="editItem(item)" />
        </CCol>
      </template>
      <template #name="{ item }">
        <td>
          <TMessage :content="item.name" noTranslate />
        </td>
      </template>
      <template #max_volume="{ item }">
        <td>
          <TMessageVolume :value="item.max_volume" class="font-weight-bold" />
        </td>
      </template>
      <template #max_weight="{ item }">
        <td>
          <TMessageNumber
            :value="item.max_weight"
            class="font-weight-bold"
            suffix="kg"
          />
        </td>
      </template>
      <template #id-filter>
        <TInputText
          placeholder="Id"
          :value.sync="filter.id"
          @update:value="filterChange"
        />
      </template>
      <template #name-filter>
        <TInputText
          placeholder="Name"
          :value.sync="filter.name"
          @update:value="filterChange"
        />
      </template>
    </TTableAdvance>
    <ContainerTypeModal :show.sync="showModalCreate" />
    <ContainerTypeModal :show.sync="showModalEdit" :detail="detail" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ContainerTypeModal from "./components/ContainerTypeModal.vue";

export default {
  components: { ContainerTypeModal },
  data() {
    return {
      fields: [
        { key: "_", _style: "width: 40px; min-width: 40px" },
        {
          key: "id",
          label: "Id",
          _style: "width: 100px; min-width: 100px",
        },
        {
          key: "name",
          label: "Name",
          _style: "width: 300px; min-width: 300px",
        },
        {
          key: "max_volume",
          label: "Max volume",
          _style: "width: 300px; min-width: 300px",
        },
        {
          key: "max_weight",
          label: "Max weight",
          _style: "width: 300px; min-width: 300px",
        },
      ],
      showModalCreate: false,
      showModalEdit: false,
      filter: {},
    };
  },
  created() {
    this.$store.dispatch("warehouse.container_types.fetch.if-first-time");
  },
  computed: {
    ...mapGetters({
      list: "warehouse.container_types.list",
      detail: "warehouse.container_types.detail",
    }),
  },
  methods: {
    filterChange() {
      const filter = this.lodash.mapKeys(this.filter, function (value, key) {
        return `filter[${key}]`;
      });
      const filterFields = this.lodash.pickBy(filter);
      this.$store.dispatch(
        "warehouse.container_types.apply-query",
        filterFields
      );
    },
    clearFilter() {
      this.filter = {};
      this.filterChange();
    },
    editItem(item) {
      this.$store.commit("warehouse.container_types.select", item.id);
      this.showModalEdit = true;
    },
  },
};
</script>
